










































import Vue from 'vue'
export default Vue.extend({
    name: 'Invoice',
    data(){
        return{

            
        }
    }
})
